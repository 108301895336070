import { Box, Flex, ListItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { TButtonWrapper, WrapperProps, WrapToggleProps } from './SWPricingCard.types';

export const PricingCardWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: ${rem('2px')};
  max-width: ${rem('345px')};
  width: 100%;
  height: 100%;

  [class*='Paragraphstyle__Paragraph-sc'] {
    text-align: left;
    padding: 0 ${rem('20px')};
    line-height: ${rem('18px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: ${rem('382px')};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    width: 70vw;
    min-width: ${rem('270px')};
    align-self: center;
  }

  [class*='ContainerWithLabelstyle__ContainerWithLabel-sc'] {
    height: 100%;

  }

  [class*='Labelstyle__LabelContainer-sc'] {
    margin-right: 0;

  }

  [class*='ContainerWithLabelstyle__Header'] {
    height: ${rem('34px')};

    [class*='ContainerWithLabelstyle__LabelContainer'] {
      padding: 0 ${rem('20px')};

      > p {
        margin-bottom: ${rem('-14px')};

        b {
          font-size: ${rem('16px')};
          font-weight: 400;
          letter-spacing: ${rem('0.7px')};
        }
      }
    }
  }

  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'] {
    box-shadow: 0 ${rem('2px')} ${rem('10px')} 0 rgba(0, 0, 0, 0.16);
    height: 100%;
    padding: 0;
    margin-bottom: ${rem('10px')};
    background-color: ${props => props.isMiddleOne && '#00697C'};

    &::before {
      border-color: ${({ theme, containerAppearance }) => {
        if (containerAppearance && containerAppearance !== 'none') {
          return theme.color[`${containerAppearance}1`].default;
        } else {
          return 'transparent';
        }
      }};
      border-style: solid;
      border-width: ${rem('1px')};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 1;
    }

    &::after {
      display: none;
    }

    [class*='Containerstyle__ContainerContent'] {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: ${rem('410px')};

      width: 100%;
      z-index: auto;

      [class*='Iconstyle__ErrorPinkBox'] {
        display: none;
      }

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        flex-direction: column;
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        max-width: ${rem('540px')};
      }
    }
  }
`;

export const CardHeading = styled(Box)``;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('30px')};
`;

export const ImageWrap = styled(Flex)`
  &,
  picture {
    width: ${rem('64px')};
    height: ${rem('64px')};
  }
`;

export const DetailItem = styled(Flex)`
  flex-direction: column;

  h5 {
    font-weight: normal;
    font-size: ${rem('14px')};
    line-height: ${rem('16px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      line-height: ${rem('20px')};
    }
  }

  h6 {
    letter-spacing: normal;
  }

  &:nth-of-type(1n + 3) {
    margin-block: ${rem('16px')} 0;

    h5 {
      font-size: ${rem('16px')};
      line-height: ${rem('15px')};

      &:last-of-type {
        height: auto;
      }
    }

    h6 {
      font-size: ${rem('46px')};
      letter-spacing: ${rem('2.2px')};
      line-height: ${rem('36px')};

      small {
        margin-right: ${rem('-4px')};
        margin-left: ${rem('-2px')};
      }
    }
    small {
      display: contents;
    }
  }
`;

export const WrapToggle = styled.div<WrapToggleProps>`
  display: flex;
  flex-direction: column;
  padding: ${rem('5px')} ${rem('23px')} ${rem('22px')};

  [class*='Togglestyle__ToggleWrapper'] {
    padding: 0;

    label::before,
    input {
      width: ${rem('27px')};
      height: ${rem('20px')};
      top: 0;
    }

    label {
      padding-left: ${rem('38px')};

      span {
        color: ${({ theme, toggleStatus }) => {
          return toggleStatus ? 'var(--vodafone-colors-mineShaft)' : theme.color.disabled.default;
        }};
        line-height: ${rem('20px')};
        opacity: ${({ toggleStatus }) => (toggleStatus ? 1 : 0.7)};

        &,
        small {
          display: block;
        }

        small {
          font-size: ${rem('12px')};
          line-height: ${rem('13px')};
        }
      }

      [class*='Togglestyle__ToggleSlider'] {
        width: ${rem('18px')};
        height: ${rem('18px')};
        top: ${rem('1px')};
        transform: translateX(${({ toggleStatus }) => (toggleStatus ? `${rem('9px')}` : 0)});
      }

      svg {
        * {
          stroke: var(--white);
        }
      }
    }
  }
`;

export const AddonWrapToggle = styled.div<WrapToggleProps>`
  display: flex;
  flex-direction: column;
  padding: ${rem('5px')} ${rem('23px')} ${rem('22px')};
  [class*='Togglestyle__ToggleWrapper'] {
    padding: 0;
    label::before,
    input {
      width: ${rem('27px')};
      height: ${rem('20px')};
      margin-top: ${rem('1px')};
    }
    [class*='Togglestyle__ToggleSlider'] {
      height: ${rem('18px')};
      width: ${rem('18px')};
      transform: translateX(${({ toggleStatus }) => (toggleStatus ? `${rem('9px')}` : 0)});
    }

    label {
      padding-left: ${rem('38px')};
      [class*='Togglestyle__IconWrapper'] {
        svg {
          * {
            stroke: var(--white);
          }
        }
      }
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  max-width: ${rem('190px')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('14px')};
    max-width: ${rem('140px')};
  }
`;
export const BundleIncludesItem = styled(ListItem)`
  [class*='Tooltipstyle__TooltipTrigger'] {
    margin-left: ${rem('9px')};
    margin-top: ${rem('-2px')};
  }

  [class*='Tooltipstyle__TooltipWrapper'] {
    max-width: none;
  }

  [class*='Tooltipstyle__TooltipBody'],
  [class*='Tooltipstyle__TooltipArrow']::before {
    background-color: var(--vodafone-colors-mineShaft);
  }

  [class*='Tooltipstyle__TooltipBody'] {
    border-radius: ${rem('6px')};
    box-shadow: 0 ${rem('4px')} ${rem('10px')} 0 rgba(0, 0, 0, 0.32);
    font-size: ${rem('18px')};
    line-height: ${rem('24px')};
    max-width: ${rem('341px')};
    padding: ${rem('20px')};
    white-space: pre-wrap;
    z-index: 2;
  }
  [class*='Togglestyle__LabelText'] {
    display: flex;
    align-items: center;
  }
`;

export const InputWrapper = styled(Flex)`
  align-items: center;
  position: relative;

  [class*='TextInputstyle__TextInput-sc'] {
    color: var(--vodafone-color-mineShaft);
    font-size: ${rem('20px')};
    height: ${rem('50px')};
    line-height: ${rem('23px')};
    padding: ${rem('12px')} ${rem('4px')};
    text-align: center;
    width: 100%;
  }
`;

export const IconButtonWrapper = styled(Flex)`
  position: absolute;
  z-index: 1;

  [class*='IconButtonstyle__IconButton-sc'] {
    background: transparent;

    [class*='Iconstyle__Icon-sc'] * {
      stroke: #007c92;
    }

    svg {
      width: ${rem('24px')};
      height: ${rem('24px')};
    }
  }
`;

export const ButtonWrapper = styled.div<TButtonWrapper>`
  display: block;
  width: 100%;
  color: var(--white);

  [class*='Buttonstyle__Button-sc'] {
    background: ${({ inBasket, buttonsAction, theme, buttonBackgroundColor }) => {
      if (buttonBackgroundColor) return buttonBackgroundColor;

      if (inBasket && buttonsAction === 'updateBasketWithQty') {
        return '#007c92';
      }
      if (!inBasket) {
        return theme.color.primary1.default;
      }
      return theme.color.primary1.default;
    }};
    color: ${({ buttonTextColor }) => buttonTextColor || 'white'};
    font-size: ${rem('20px')};
    height: ${rem('50px')};
    letter-spacing: 0;
    line-height: ${rem('22px')};
    min-width: ${({ buttonsAction }) => {
      if (buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') {
        return rem('130px');
      }
      return rem('192px');
    }};
    padding: ${rem('16px')} 0;
    width: 100%;
  }
`;

export const FCWrapper = styled.div<{ isFlipped?: boolean }>`
  width: 100%;
  display: flex;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  flex-direction: column;
  align-items: center;
  ${({ isFlipped }) => {
    if (isFlipped) {
      return 'transform: rotateY(90deg);';
    }
  }};
  [class*='Interactionstyle'] {
    width: 90%;
    border-radius: ${rem('6px')};

    &[id='FlipButton'] {
      position: absolute;
      bottom: ${rem('10px')};
    }
    &[id='VisitProductButton'] {
      position: absolute;
      bottom: ${rem('80px')};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
`;

export const CTAWrapper = styled.div`
  margin-top: ${rem('-20px')};

  [class*='Interactionstyle__Button-sc'] {
    border-radius: ${rem('6px')};
    &[id='basketButtonAction'] {
      background-color: #0096ad;
      border-color: #0096ad;
    }
  }
  [class*='TextInputstyle__TextInput-sc'] {
    border-radius: ${rem('6px')};
  }

  div > div {
    border-top: 0;
  }
`;
